import React from "react"
import emailjs from "emailjs-com"
import { Container } from "../shared"
import './HireMe.css'

function sendEmail(e) {
  e.preventDefault()

  emailjs
    .sendForm(
      "webmostafa",
      "template_e94rwjl",
      e.target,
      "user_tfbbQLj1stMt4S8jQAbKF"
    )
    .then(
      result => {
        console.log(result.text)
      },
      error => {
        console.log(error.text)
      }
    )

  alert("Thank you for your interest. We will back to you soon.")
}

const HireMe = () => {
  return (
    <Container>
      <div className="hire-me-area">
        <div className="contact-form-area hire-me-form">
          <form className="contact-form" onSubmit={sendEmail}>
            <h2 align="center">Submit Your Proposal</h2>
            <label>Name</label>
            <input type="text" name="user_name" placeholder="Your Name" />
            <label>Email</label>
            <input type="email" name="user_email" placeholder="Your Email" />
            <label>Your Project Details</label>
            <textarea name="message" placeholder="Your Project Details" />
            <input
              type="submit"
              style={{ backgroundColor: "#d8624c", marginTop: "10px" }}
              value="Submit"
            />
            <p>
              Sending to{" "}
              <i style={{ color: "#d8624c" }}>mostafa@mdmostafa.com</i>
            </p>
            <p>*Your information to us is safe and secure.</p>
          </form>
        </div>
      </div>
    </Container>
  )
}

export { HireMe }
