import React from "react"

import { Layout, SEO } from "../components/shared"
import { HireMe } from "../components/HireMe/HireMe"

const HireMePage = (props) => (
  <>
    <Layout locationPath={props.location.pathname}>
        <SEO title="Hire Me, Get Support | mdmostafa.com" />
        <HireMe />
      </Layout>
  </>
)

export default HireMePage;